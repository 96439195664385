import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';

// ------------------ 1. Declare global types ------------------
declare global {
  interface Window {
    Intercom?: any;
    intercomSettings?: any;
  }
}

function IntercomProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Base config
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'eihrq8pc', // <-- remove extra quotes here
    };

    // Intercom loader script
    (function () {
      const w = window as any;
      const ic = w.Intercom;

      if (typeof ic === 'function') {
        // Intercom is already defined, just update it
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        // Otherwise, create the Intercom stub
        const d = document;
        const i = function (...restArgs: any[]) {
          i.c(restArgs);
        } as any;

        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;

        // Use a function expression rather than a declaration
        const loadIntercom = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/eihrq8pc'; // must match your app ID
          const x = d.getElementsByTagName('script')[0];
          if (x && x.parentNode) {
            x.parentNode.insertBefore(s, x);
          }
        };

        // Load the script on window load
        if (d.readyState === 'complete') {
          loadIntercom();
        } else if (w.attachEvent) {
          w.attachEvent('onload', loadIntercom);
        } else {
          w.addEventListener('load', loadIntercom, false);
        }
      }
    })();
  }, []);

  return <>{children}</>;
}

// ------------------ 3. Render App as usual ------------------
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <IntercomProvider>
          <App />
        </IntercomProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
