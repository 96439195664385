// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Engagespot } from '@engagespot/react-component';
// API
import axios from 'src/utils/axios';
import useSWR from 'swr';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
} from '../_common';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

const themeEngage = {
  notificationButton: {
    background: '', // A blue background color
    hoverBackground: '', // Darker blue on hover
    iconFill: 'black',
    floatingButtonShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Light shadow for depth

    // outline: 'none', // No outline
    // margin: '10px', // Margin around the button
    // padding: '12px', // Padding inside the button
    // borderWidth: '2px', // Border width
    // normalButtonRadius: '4px', // Border radius for normal button
    floatingButtonRadius: '50%', // Circular button for floating type
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease' // Smooth transition effects
  },
}

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const fetcher = (url: string) => axios.get(url).then(res => res.data);

export default function Header({ onOpenNav }: Props) {

  const { data: userInfo, error: userInfoError } = useSWR(`${apiUrl}/user/general`, fetcher);

  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const isLoading = !userInfo;
  const hasError = userInfoError;
  if (isLoading) return <LoadingScreen />;
  if (hasError) return <div>Error loading data</div>;

  window.Intercom?.('update', {
    app_id: 'eihrq8pc',
    user_id: userInfo.id,
    company: `${userInfo.account_id}`,
    email: userInfo.email,
    name: `${userInfo.firstName} ${userInfo.lastName}`,
  });

  return (

    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        <>
          {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

          {!lgUp && (
            <IconButton onClick={onOpenNav}>
              <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
            </IconButton>
          )}


          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
          >
            {userInfo && userInfo.id && (
              <Engagespot
                apiKey="6fahkcvecwp90tk7cogv5d"
                userId={userInfo.id}
                userToken=''
                theme={themeEngage}
              />
            )}
            <AccountPopover />
          </Stack>
        </>
      </Toolbar>
    </AppBar>
  );
}
